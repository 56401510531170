<template>
  <main>
    <router-view />
  </main>
  <footer>
    <p>
      I&nbsp;agree to the processing of personal data provided in this document
      for realising the recruitment process pursuant to the Personal Data
      Protection Act of 10 May 2018 (Journal of Laws 2018, item 1000) and in
      agreement with Regulation (EU) 2016/679 of the European Parliament and of
      the Council of 27 April 2016 on the protection of natural persons with
      regard to the processing of personal data and on the free movement of such
      data, and repealing Directive 95/46/EC (General Data Protection
      Regulation).
    </p>
    <p>
      Wyrażam zgodę na przetwarzanie moich danych osobowych dla potrzeb
      niezbędnych do realizacji procesu rekrutacji zgodnie
      z&nbsp;Rozporządzeniem Parlamentu Europejskiego i&nbsp;Rady (UE) 2016/679
      z&nbsp;dnia 27 kwietnia 2016&nbsp;r. w&nbsp;sprawie ochrony osób
      fizycznych w związku z przetwarzaniem danych osobowych
      i&nbsp;w&nbsp;sprawie swobodnego przepływu takich danych oraz uchylenia
      dyrektywy 95/46/WE (RODO).
    </p>
  </footer>
  <div class="up-logos">
    <div class="container">
      <div class="img">
        <img src="/fe.png" alt="Fundusze Europejskie" />
      </div>
      <div class="img">
        <img src="/rp.png" alt="Rzeczpospolita Polska" />
      </div>
      <div class="img">
        <img src="/ue.png" alt="Unia Europejska" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "scss/reset.scss";

:root {
  --c-secondary: #75ac64;
  --c-grey: #232323;
}

body {
  font-family: "Oswald", sans-serif;
  font-weight: 200;
  background: #e1e1e1;
  color: var(--c-grey);
}

main {
  min-height: 100vh;
}

h2 {
  text-align: center;
  font-size: 3vw;
  font-weight: 500;

  @media (max-width: 1250px) {
    font-size: 37.5px;
  }
}

h3 {
  text-align: center;
  font-size: 2vw;
  font-weight: 500;

  @media (max-width: 1250px) {
    font-size: 25px;
  }
}

a {
  color: #0066ec;
  text-decoration: none;

  &:visited {
    opacity: 0.7;
  }
}

footer {
  margin-top: 20px;
  background-color: var(--c-grey);

  p {
    line-height: 1.5;
    max-width: 980px;
    margin: 0 auto;
    text-align: center;
    font-size: 11px;
    letter-spacing: 1px;
    color: white;
    padding: 20px 10px;
  }
}

.up-logos {
  background-color: white;

  .container {
    max-width: 1080px;
    margin: 0 auto;
    padding: 20px 20px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
  }

  .img {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    height: 100px;
  }

  img {
    max-width: 100%;
    max-height: 100px;

    @media screen and (max-width: 767px) {
      max-width: 180px;
    }
  }
}
</style>
