
import { defineComponent } from "vue";
import IconComponent from "@/components/mols/icon/Icon.vue";
import { IconKey } from "@/components/mols/icon/iconset";
import HeaderComponent from "@/components/mols/header/Header.vue";

export default defineComponent({
  name: "AgaView",

  components: {
    IconComponent,
    HeaderComponent,
  },

  setup() {
    const yearsOld = new Date().getFullYear() - 1997;

    return {
      IconKey,
      yearsOld,
    };
  },
});
